import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface FormProps {
  meetingDay: string;
  setMeetingDay: Dispatch<SetStateAction<string>>;
  meetingTime: string;
  setMeetingTime: Dispatch<SetStateAction<string>>;
}

interface DaysType {
  day: string;
  month: string;
  dayNumerical: number;
  date: string;
  rawDate: string;
}
interface TimesType {
  time: string;
  span: string;
}
const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
const months = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC'
];
const times = [
  { time: '10:00', span: 'AM', rawTime: '10:00:00' },
  { time: '10:30', span: 'AM', rawTime: '10:30:00' },
  { time: '11:00', span: 'AM', rawTime: '11:00:00' },
  { time: '11:30', span: 'AM', rawTime: '11:30:00' },
  { time: '12:00', span: 'PM', rawTime: '12:00:00' },
  { time: '12:30', span: 'PM', rawTime: '12:30:00' },
  { time: '01:00', span: 'PM', rawTime: '13:00:00' },
  { time: '01:30', span: 'PM', rawTime: '13:30:00' },
  { time: '02:00', span: 'PM', rawTime: '14:00:00' }
];

const DaysSlots = ({
  meetingDay,
  setMeetingDay,
  meetingTime,
  setMeetingTime
}: FormProps) => {
  const { t } = useTranslation('contact');
  const [availableDays, setAvailableDays] = useState<DaysType[]>([]);
  const [availableTimes, setAvailableTimes] = useState<TimesType[]>([]);

  const formatDate = (inputDate: string): string => {
    const [year, month, day] = inputDate.split('-');
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    let availableSlots: DaysType[] = [];
    for (let i = 0; i <= 7; ++i) {
      let date = new Date();
      date.setDate(date.getDate() + i);
      const day = days[date.getDay()];
      const month = months[date.getMonth()];

      availableSlots.push({
        day: day,
        month,
        dayNumerical: date.getDate(),
        date: `${day} ${date.getDate()}-${month}-${date.getFullYear()}`,
        rawDate: formatDate(date.toISOString().split('T')[0])
      });
    }
    setAvailableDays(availableSlots);
    setAvailableTimes(times);
    setMeetingDay(formatDate(availableSlots[0].rawDate));
    setMeetingTime(times[0].time + times[0].span);
  }, []);

  return (
    <>
      <div className="form-row">
        <h3 className="headline-2 headline">{t('select_available_dates')}</h3>
        <Container>
          <div className="slots-container">
            {availableDays.map((day: any) => (
              <div
                className={
                  day.rawDate == meetingDay ? 'day-slot selected' : 'day-slot'
                }
                key={day.dayNumerical}
                onClick={() => setMeetingDay(day.rawDate)}
              >
                <div className="week-day headline-3">{day.day}</div>
                <div className="day-date text-1">
                  {day.dayNumerical} {day.month}
                </div>
              </div>
            ))}
          </div>
        </Container>
      </div>
      <div className="form-row">
        <h3 className="headline-2 headline">{t('available_times')}</h3>
        <Container>
          <div className="slots-container">
            {availableTimes.map((time: any) => (
              <div
                className={
                  time.time + ' ' + time.span == meetingTime
                    ? 'time-slot selected'
                    : 'time-slot'
                }
                key={time.time}
                onClick={() => setMeetingTime(time.time + ' ' + time.span)}
              >
                <div className="time">
                  {time.time} <span>{time.span}</span>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </div>
    </>
  );
};

const Container = styled.div`
  width: 100%;

  .slots-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;

    .day-slot,
    .time-slot {
      padding: 5px;
      border: 1px solid ${props => props.theme.light.borderColors.primary};
      border-radius: ${props => props.theme.borderRadius};
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 60px;
      cursor: pointer;

      .week-day,
      .time {
        color: ${props => props.theme.light.colors.tertiary};
        font-size: 16px;

        span {
          color: ${props => props.theme.light.colors.midGray};
          font-size: 14px;
        }
      }

      .day-date {
        font-size: 14px;
      }
      &.selected {
        border: 1px solid ${props => props.theme.light.colors.tertiary};
        box-shadow: 0px 4px 4px 0px #00000026;
      }
      &:hover {
        @media (hover: hover) and (pointer: fine) {
          border: 1px solid ${props => props.theme.light.colors.tertiary};
        }
      }
    }
  }

  @media screen and (max-width: ${props =>
      props.theme.breakPoints.tablet.max}) {
    overflow-x: auto;
    ::-webkit-scrollbar {
      display: none;
    }
    .slots-container {
      width: max-content;
      flex-wrap: nowrap;
    }
  }
`;

export default DaysSlots;
