const MeetingIcon = () => {
  return (
    <svg
      width="32"
      height="26"
      viewBox="0 0 32 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0051 12.4025C16.0051 9.38671 15.9211 6.46532 16.0365 3.54918C16.11 1.66628 17.8198 0.161007 19.7341 0.134783C23.0017 0.0928242 26.2692 0.108559 29.5368 0.129538C30.7116 0.140028 31.4774 0.937246 31.4879 2.10685C31.5036 4.89711 31.5036 7.68737 31.4879 10.4776C31.4826 11.5109 30.7221 12.3815 29.7256 12.392C25.1836 12.4235 20.6415 12.4025 16.0051 12.4025ZM27.9738 10.614C27.8689 9.64371 27.827 8.71012 27.6486 7.81325C27.4283 6.71183 26.4423 6.37092 25.5559 7.02128C24.4493 7.83423 23.0436 7.83423 21.9317 7.02128C21.0559 6.38141 20.0436 6.73806 19.839 7.8185C19.6712 8.72061 19.6292 9.64895 19.5244 10.614C22.3671 10.614 25.1363 10.614 27.9738 10.614ZM23.7202 1.87083C22.2359 1.88656 21.0873 3.06666 21.0926 4.58242C21.0978 6.08769 22.2674 7.27828 23.7412 7.27828C25.2412 7.27828 26.4423 6.04573 26.4266 4.51948C26.4108 3.04568 25.194 1.8551 23.7202 1.87083Z"
        fill="#666666"
      />
      <path
        d="M14.3949 18.5963C10.8861 18.5963 7.48746 18.5963 4.08355 18.5963C3.4699 18.5963 2.85625 18.6173 2.24785 18.5858C1.19363 18.5333 0.517046 17.841 0.511801 16.7921C0.496066 14.3165 0.496066 11.8409 0.511801 9.37058C0.517046 8.26916 1.20937 7.59258 2.32128 7.58733C5.22168 7.5716 8.12209 7.56635 11.0172 7.59258C12.7271 7.60831 14.2743 8.85659 14.3582 10.5349C14.4841 13.1836 14.3949 15.8427 14.3949 18.5963ZM11.2323 16.9914C11.1589 16.1364 11.1484 15.3182 11.0015 14.521C10.8179 13.5193 9.92107 13.2151 9.09238 13.8182C8.12209 14.521 6.77416 14.521 5.81959 13.813C5.02238 13.2203 4.14649 13.4825 3.94718 14.4581C3.77935 15.271 3.76886 16.1207 3.68494 16.9914C6.23918 16.9914 8.69377 16.9914 11.2323 16.9914ZM7.46123 13.9965C8.77769 13.9965 9.90009 12.8637 9.88436 11.5367C9.86862 10.236 8.78818 9.1503 7.4927 9.12932C6.171 9.10834 5.04336 10.215 5.03287 11.5472C5.02238 12.8741 6.13953 13.9965 7.46123 13.9965Z"
        fill="#666666"
      />
      <path
        d="M3.56912 18.9584C3.89954 18.9584 4.13031 18.9532 4.36633 18.9584C4.57088 18.9637 4.78068 18.9794 5.02718 18.9951C5.05865 19.4462 5.08488 19.8605 5.11635 20.3273C12.648 20.3273 20.1586 20.3273 27.7531 20.3273C27.7531 17.8518 27.7531 15.3919 27.7531 12.8691C28.2724 12.8691 28.7129 12.8691 29.1955 12.8691C29.2165 13.037 29.2532 13.2153 29.2532 13.3936C29.2584 15.7591 29.2584 18.1297 29.2532 20.4952C29.2532 21.3973 28.9647 21.6962 28.0678 21.6962C20.3054 21.7015 12.5431 21.7015 4.78068 21.6962C3.86283 21.6962 3.59009 21.4183 3.56912 20.5161C3.55863 20.0441 3.56912 19.5668 3.56912 18.9584Z"
        fill="#666666"
      />
      <path
        d="M22.6768 25.8922C18.4652 25.8922 14.306 25.8922 10.1416 25.8922C10.1783 24.9586 10.6713 24.3764 11.5315 24.3712C14.7728 24.3502 18.0141 24.3502 21.2554 24.3712C22.1261 24.3764 22.6296 24.9376 22.6768 25.8922Z"
        fill="#666666"
      />
      <path
        d="M15.538 4.57173C15.538 5.02279 15.538 5.38469 15.538 5.86197C12.0764 5.86197 8.61478 5.86197 5.12171 5.86197C5.08499 6.33925 5.05877 6.70115 5.02206 7.11024C4.55002 7.11024 4.10945 7.11024 3.65315 7.11024C3.62693 7.04206 3.57448 6.97388 3.57448 6.90045C3.51154 4.58747 3.52727 4.54027 5.78256 4.56124C8.73541 4.59271 11.683 4.56649 14.6359 4.56649C14.9296 4.57173 15.2128 4.57173 15.538 4.57173Z"
        fill="#666666"
      />
      <path
        d="M19.8022 22.1738C19.8022 22.7875 19.8022 23.312 19.8022 23.8889C17.5259 23.8889 15.2864 23.8889 13.0049 23.8889C13.0049 23.312 13.0049 22.7665 13.0049 22.1738C15.2707 22.1738 17.5155 22.1738 19.8022 22.1738Z"
        fill="#666666"
      />
    </svg>
  );
};

export default MeetingIcon;
