import { useState, useEffect, SetStateAction, Dispatch } from 'react';
import dynamic from 'next/dynamic';
import { useContactForm } from '../../hooks/ContactForm';
import RequestMeetingPresentation from './RequestMeetingPresentation';
const SuccessModal = dynamic(() => import('../SuccessModal'), { ssr: false });

interface FormProps {
  location?: {
    areaId?: string;
    developerId?: string;
    compoundId?: string;
    propertyId?: string;
  };
  setFormOpen: Dispatch<SetStateAction<boolean>>;
}

const RequestMeetingContainer = ({ location, setFormOpen }: FormProps) => {
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [meetingLocation, setMeetingLocation] = useState<string>('zoom');
  const [meetingDay, setMeetingDay] = useState<string>('');
  const [meetingTime, SetMeetingTime] = useState<string>('');

  const onFormSubmit = () => {
    setSuccessModal(true);
    setTimeout(() => {
      setSuccessModal(false);
      setFormOpen(false);
    }, 3000);
  };
  const getFormExtraData = () => {
    formik.setFieldValue('contact_date', meetingDay);
    formik.setFieldValue('contact_time', `${meetingTime}`);
    formik.setFieldValue('contact_meeting_location', meetingLocation);
  };

  useEffect(() => {
    getFormExtraData();
  }, [meetingDay, meetingTime, meetingLocation]);

  const { onPhoneNumberChange, formik, customerNumber } = useContactForm({
    onFormSubmit,
    formSource: 'scheduleMeeting',
    location: location
  });

  return (
    <>
      <RequestMeetingPresentation
        formik={formik}
        customerNumber={customerNumber}
        onPhoneNumberChange={onPhoneNumberChange}
        meetingDay={meetingDay}
        setMeetingDay={setMeetingDay}
        meetingTime={meetingTime}
        setMeetingTime={SetMeetingTime}
        meetingLocation={meetingLocation}
        setMeetingLocation={setMeetingLocation}
      />
      {successModal && <SuccessModal />}
    </>
  );
};
export default RequestMeetingContainer;
